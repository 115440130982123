import { useEffect } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';

import { withMap, latLngToLngLat, inBounds } from '@strava/ui/Map';

const getBoundsFromLatLng = (latLng) => {
  const lngLat = latLngToLngLat(latLng);
  const bounds = lngLat.reduce((b, coord) => {
    return b.extend(coord);
  }, new mapboxgl.LngLatBounds());

  return [bounds.getSouthWest(), bounds.getNorthEast()];
};

function FitBounds({ map, latlng, start, end, updateOnLatlngChange }) {
  useEffect(() => {
    map.fitBounds(
      getBoundsFromLatLng(latlng),
      { padding: 48 },
      { source: 'fitBounds' }
    );
  }, []);

  useEffect(() => {
    if (!inBounds({ lng: start[1], lat: start[0] }, map.getBounds())) {
      map.flyTo({ center: latLngToLngLat(start), speed: 2 });
    }
  }, [start]);

  useEffect(() => {
    if (!inBounds({ lng: end[1], lat: end[0] }, map.getBounds())) {
      map.flyTo({ center: latLngToLngLat(end), speed: 2 });
    }
  }, [end]);

  useEffect(() => {
    if (updateOnLatlngChange) {
      map.fitBounds(
        getBoundsFromLatLng(latlng),
        { padding: 48 },
        { source: 'fitBounds' }
      );
    }
  }, [latlng]);

  return null;
}

FitBounds.defaultProps = {
  updateOnLatlngChange: false
};

FitBounds.propTypes = {
  updateOnLatlngChange: PropTypes.bool,
  latlng: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  map: PropTypes.shape().isRequired,
  start: PropTypes.arrayOf(PropTypes.number).isRequired,
  end: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default withMap(FitBounds);
